import { MergeObjects } from './../core/library/objects';
import MediaSources from './media_sources';
import * as Projects from './projects';
import {parseUrlParams} from "../core/library/url";
import PushRegions from './regions';

const PROJECT = process.env.PROJECT || "";


let Config = {
    LOCALE: "ru",
    PROJECT_PREFIX: "test",
    // project config
    PROJECT: {
        // project name
        NAME: PROJECT,
        // project default layout
        LAYOUT: "default",
        // project api path
        API: `http://localhost:8086/api/backend`,
    },

    // oauth configuration
    OAUTH: {
        // url for oauth domain
        URL: `http://emauth.vmdaily.ru`,
        // current project domain
        PROJECT_DOMAIN: `localhost:3000`,
        // current project url path
        PROJECT_PATH: `http://localhost:3000`,
        // path for oauth api
        API: `http://emauth.vmdaily.ru/auth`,
        // path for oauth users
        OAUTH_USER_PATH: `http://emauth.vmdaily.ru/users/`,
        // prefix for authorization tokens
        PREFIX: 'oauth_',
    },
    // default date format
    DATE_FORMAT: "DD-MM-YYYY HH:mm",
    // configuration for slug plugin
    SLUG: {
        delimiter: '-',
        prefix: ''
    },

    // configuration for media
    MEDIA: {
        // path for cdn content
        CDN: {
            IMAGES: "https://cdni.rbth.com/rbthmedia/images",
            VIDEO: "https://cdni.rbth.com/rbthmedia/video"
        },
        // available copyright sources
        SOURCES: MediaSources,
        // available upload types
        UPLOAD_AVAILABLE: ['jpg','gif','png','jpeg','mp4','mp3'],
        // available embedded sources
        EMBEDDED_SOURCES: ['youtube.com', 'facebook.com', 'youtu.be'],
        EMEDDED_PREPARE: {
            'youtu.be': (path) => {
                let params = path.split('/');
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${params[params.length - 1]}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'youtube.com': (path) => {
                let p = parseUrlParams(path);
                if (!p.v) return ``;
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p.v}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'facebook.com': (url) => {
                return (
                    `<iframe src="https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&width=500&show_text=false&appId=526970354336335&height=280" width="500" height="280" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`
                )
            },
        },
    },

    DOCUMENTS: {
        STATUS: ["published", "unpublished"],
        MOVE_TYPES: ['News', 'Article', "Meaning", "Embargo"],
    },
    EDITOR: {
        KEY: "clzzivqs4p1bb1eoei67nmbzwwzio5uv0w69ew52cc8ex5s7",
        SETTINGS: {
            language: 'ru',
        },
    },
    MAPS: {
        KEY: "61db0816-734e-4473-8924-5e906e882a8b",
    },
    ELASTIC: {
        API: "http://localhost:8009/elastic"
    },
    MEDIA_DATABASE_TYPES: ['Image', 'Video', 'ExternalVideo', 'Code'],
    MEDIA_AVAILABLE_TYPES: '.jpg,.gif,.png,.jpeg,.mp4,.mp3',
    MEDIA_AVAILABLE_SOURCES: ['youtube', 'vimeo', 'facebook'],
    WIDGETS: {
        TYPES: ['Article', 'News', 'Episode', 'Infographic','Meaning', 'Trend', 'Banner', 'Show', 'Polls']
    },
    REGIONS: PushRegions,
    PHOTO_AUTHORS: [
        `Пелагия Замятина, «Вечерняя Москва»`,
        `Антон Гердо, «Вечерняя Москва»`,
        `Алексей Орлов, «Вечерняя Москва`,
        `Светлана Колоскова, «Вечерняя Москва»`,
        `Александр Казаков, «Вечерняя Москва»`,
        `Анна Быкова, «Вечерняя Москва»`,
        `Александр Кожохин, «Вечерняя Москва»`,
        `Денис Кондратьев, «Вечерняя Москва»`,
        `Павел Волков, «Вечерняя Москва»`,
        `Алексей Орлов, «Вечерняя Москва»`,
        `АГН «Москва»`,
        `Сергей Ведяшкин/АГН «Москва»`,
        `Александр Авилов/АГН «Москва»`,
        `Кирилл Зыков/АГН «Москва»`,
        `Сергей Киселев/АГН «Москва»`,
        `Андрей Никеричев/АГН «Москва»`,
        `Софья Сандурская/АГН «Москва»`,
        `Мобильный репортер/АГН «Москва»`,
        `mos.ru/Официальный сайт Мэра Москвы`,
        `mos.ru/Максим Денисов`,
        `mos.ru/Юлия Иванко`,
        `mos.ru/Евгений Самарин`,
        `Пресс-служба Мэра и Правительства Москвы/Денис Гришкин`,
        `Пресс-служба Мэра и Правительства Москвы/Максим Мишин`,
        `Пресс-служба Мэра и Правительства Москвы/Евгений Самарин`,
        `kremlin.ru/Администрация Президента России`,
    ],

    SPECIAL_PROJECT_TYPES: ['Фотовыставка', 'Спец. репортаж', 'Выставка', 'Специальный материал']

};

if(Projects[PROJECT]) {
    Config = MergeObjects(Config, Projects[PROJECT]);
}

window.APP_CONFIG = {
    PROJECT: Config.PROJECT,
    MEDIA: Config.MEDIA,
};

export default Config;
